<template>
  <a-modal v-model="modalVisible" title="人员选择" :width="800" :centered="true">
    <template slot="footer">
      <a-button @click="modalVisible=false">取消</a-button>
      <a-button type="primary" @click="confirm">确认</a-button>
    </template>
    <div class="select-modal-container">
      <a-form-model ref="queryForm" :model="queryParams" layout="inline">
<!--        <a-form-model-item label="公司/项目" prop="userdepsid">-->
<!--          <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype!='0'?deptOptions.filter(item => item.deptype=='1'):deptOptionsAll.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected" :load-data="userInfo.usertype!='0'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>-->
<!--        </a-form-model-item>-->
        <a-form-model-item label="用户姓名" prop="username">
          <a-input v-model.trim="queryParams.username" placeholder="请输入"></a-input>
        </a-form-model-item>
        <a-form-model-item label="用户账号" prop="useraccount">
          <a-input v-model.trim="queryParams.useraccount" placeholder="请输入"></a-input>
        </a-form-model-item>
        <a-form-model-item>
          <a-button @click="resetQueryForm">重置</a-button>
          <a-button type="primary" style="margin-left: 10px;" @click="getTableData(true)">查询</a-button>
        </a-form-model-item>
      </a-form-model>
      <a-table :columns="tableColumns" :data-source="tableData" row-key="userid" :row-selection="{ type: selectType, selectedRowKeys: selectedRowKeys, onSelect: onSelectRow, onSelectAll: onSelectAllRows }" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 16px;" :pagination="false">
        <span slot="status" slot-scope="text" class="status-view">
          <span class="dot" :class="{'light': text=='1'}"></span>
          <span class="text">{{text=='1' ? '启用' : '未启用'}}</span>
        </span>
      </a-table>
      <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" @change="pageChange" style="margin-top: 20px; text-align: right;">
        <template slot="buildOptionText" slot-scope="props">
          <span>{{ props.value }}条/页</span>
        </template>
      </a-pagination>
    </div>
  </a-modal>
</template>
<script>
import deptselect from '@/mixins/deptselect'
import {mapState} from "vuex";
import {getAllOrderUserByMonitorPointNum} from "A/repairsreport";
export default {
  mixins: [deptselect],
  props: {
    visible: {
      default: false
    },
    selectType: {
      default: 'checkbox'
    },
    defaultSelected: {
      default() {
        return []
      }
    },
    params: {
      default: null
    }
  },
  data() {
    return {
      modalVisible: false,
      queryParams: {
        useraccount: '',
        username: '',
        userdepid: '',
        rolename:'',
        monitorpointnum:'',
      },
      maintenancedepidList: [],
      userdepidCascaderSelected:[],
      tableLoading: false,
      tableColumns: [
        {
          title: '账号',
          dataIndex: 'useraccount',
          key: 'useraccount'
        },
        {
          title: '姓名',
          dataIndex: 'username',
          key: 'username'
        },
        {
          title: '角色',
          dataIndex: 'rolename',
          key: 'rolename'
        },
        // {
        //   title: '所属机构',
        //   dataIndex: 'userdepname',
        //   key: 'userdepname'
        // },
      ],
      tableData: [],
      selectedRows: [],
      currentPage: 1,
      pagination: {
        total: 0,
        pageSize: 5,
      }
    }
  },
  computed: {
    selectedRowKeys() {
      let currentSelectKeys = this.selectedRows.map(item => item.userid);
      return currentSelectKeys;
    },
    ...mapState({
      userInfo: state => state.userInfo,
    }),
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.selectedRows = this.defaultSelected || [];
        this.init();
      }else {
        this.selectedRows = []
      }
    },
    modalVisible(val) {
      if(!val) {
        this.$emit('update:visible', false);
      }
    },
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepsid = val[val.length-1];
      }else {
        this.queryParams.userdepsid = '';
      }
    },
    params(val) {
      if(val) {
        this.queryParams = {...this.queryParams, ...val};
      }
    },
  },
  created() {
    // this.init();
  },
  methods: {
    init() {
      if(this.params) {
        this.queryParams = {...this.queryParams, ...this.params};
      }
      this.modalVisible = this.visible;
      this.getTableData(true);
    },
    resetQueryForm() {
      this.$refs.queryForm.resetFields();
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize,
      };
      getAllOrderUserByMonitorPointNum(params).then(res => {
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      })
    },
    pageChange(page, size) {
      this.getTableData();
    },
    onSelectRow(record, selected, selectedRows, nativeEvent) {
      if(selected) {
        if(this.selectType == 'radio') {
          this.selectedRows = [record]
        }else if(this.selectedRowKeys.indexOf(record.userid) < 0) {
          this.selectedRows.push(record);
        }
      }else {
        this.selectedRows = this.selectedRows.filter(item => item.userid!=record.userid)
      }
    },
    onSelectAllRows(selected, selectedRows, changeRows) {
      if(selected) {
        this.tableData.forEach(record => {
          if(this.selectedRowKeys.indexOf(record.userid) < 0) {
            this.selectedRows.push(record);
          }
        })
      }else {
        this.tableData.forEach(record => {
          this.selectedRows = this.selectedRows.filter(item => item.userid!=record.userid)
        })
      }
    },
    confirm() {
      this.selectedRows[0].order_id=this.params.order_id
      this.$emit('get-selected-rows', this.selectedRows);
      this.modalVisible = false;
    }
  }
}
</script>