var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"dynamic-wrap"},[_vm._l((_vm.keysList),function(item){return _c('div',{key:item},[_c('a-form-item',{staticStyle:{"width":"45%"},attrs:{"required":"","label":"工单类型","labelCol":{span: 9},"wrapperCol":{span: 15}}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  (_vm.title + "order_type[" + item + "]"),
                  {
                    initialValue: _vm.arr[item] ? _vm.arr[item].order_type : undefined,
                  } ]),expression:"[\n                  `${title}order_type[${item}]`,\n                  {\n                    initialValue: arr[item] ? arr[item].order_type : undefined,\n                  },\n                ]"}],staticStyle:{"width":"226px"},attrs:{"disabled":_vm.showType==='detail',"placeholder":""},on:{"change":function($event){return _vm.orderTypeChange(item,_vm.arr[item])}}},[_c('a-select-option',{attrs:{"value":0}},[_vm._v("报事")]),_c('a-select-option',{attrs:{"value":1}},[_vm._v("报修")]),_c('a-select-option',{attrs:{"value":2}},[_vm._v("第三方工单")])],1)],1),(_vm.arr[item].order_type===0)?_c('a-form-item',{staticStyle:{"width":"45%"},attrs:{"required":"","label":"报事来源","labelCol":{span: 9},"wrapperCol":{span: 15}}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  (_vm.title + "order_source[" + item + "]"),
                  {
                    initialValue: _vm.arr[item] ? _vm.arr[item].order_source : undefined,
                  } ]),expression:"[\n                  `${title}order_source[${item}]`,\n                  {\n                    initialValue: arr[item] ? arr[item].order_source : undefined,\n                  },\n                ]"}],staticStyle:{"width":"226px"},attrs:{"disabled":_vm.showType==='detail',"placeholder":""}},[_c('a-select-option',{attrs:{"value":"自报"}},[_vm._v("自报")]),_c('a-select-option',{attrs:{"value":"调度中心"}},[_vm._v("调度中心")]),_c('a-select-option',{attrs:{"value":"外部报"}},[_vm._v("外部报")])],1)],1):_vm._e(),(_vm.order_type===0)?_c('a-form-item',{staticStyle:{"width":"45%"},attrs:{"required":"","label":"报事类型","labelCol":{span: 9},"wrapperCol":{span: 15}}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  (_vm.title + "repair_matter_type[" + item + "]"),
                  {
                    initialValue: _vm.arr[item] ? _vm.arr[item].repair_matter_type : undefined,
                  }
                ]),expression:"[\n                  `${title}repair_matter_type[${item}]`,\n                  {\n                    initialValue: arr[item] ? arr[item].repair_matter_type : undefined,\n                  }\n                ]"}],staticStyle:{"width":"226px"},attrs:{"disabled":_vm.showType==='detail',"placeholder":""}},[_c('a-select-option',{attrs:{"value":"建议"}},[_vm._v("建议")]),_c('a-select-option',{attrs:{"value":"问询"}},[_vm._v("问询")]),_c('a-select-option',{attrs:{"value":"质疑"}},[_vm._v("质疑")]),_c('a-select-option',{attrs:{"value":"求助"}},[_vm._v("求助")])],1)],1):_vm._e(),(_vm.order_type===0)?_c('a-form-item',{staticStyle:{"width":"45%"},attrs:{"required":"","label":"报事人姓名","labelCol":{span: 9},"wrapperCol":{span: 15}}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  (_vm.title + "contacts[" + item + "]"),
                  {
                    initialValue: _vm.arr[item] ? _vm.arr[item].contacts : undefined,
                  }
                ]),expression:"[\n                  `${title}contacts[${item}]`,\n                  {\n                    initialValue: arr[item] ? arr[item].contacts : undefined,\n                  }\n                ]"}],staticStyle:{"width":"226px"},attrs:{"disabled":_vm.showType==='detail',"placeholder":""}})],1):_vm._e(),(_vm.order_type===0)?_c('a-form-item',{staticStyle:{"width":"45%"},attrs:{"required":"","label":"报事人电话","labelCol":{span: 9},"wrapperCol":{span: 15}}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  (_vm.title + "contact_information[" + item + "]"),
                  {
                    initialValue: _vm.arr[item] ? _vm.arr[item].contact_information : undefined,
                  }
                ]),expression:"[\n                  `${title}contact_information[${item}]`,\n                  {\n                    initialValue: arr[item] ? arr[item].contact_information : undefined,\n                  }\n                ]"}],staticStyle:{"width":"226px"},attrs:{"disabled":_vm.showType==='detail',"placeholder":""}})],1):_vm._e(),(_vm.order_type===0)?_c('a-form-item',{staticStyle:{"width":"45%"},attrs:{"required":"","label":"报事内容","labelCol":{span: 9},"wrapperCol":{span: 15}}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  (_vm.title + "content[" + item + "]"),
                  {
                    initialValue: _vm.arr[item] ? _vm.arr[item].content : undefined,
                  }
                ]),expression:"[\n                  `${title}content[${item}]`,\n                  {\n                    initialValue: arr[item] ? arr[item].content : undefined,\n                  }\n                ]"}],staticStyle:{"width":"226px"},attrs:{"disabled":_vm.showType==='detail',"placeholder":""}})],1):_vm._e(),(_vm.order_type===1)?_c('a-form-item',{staticStyle:{"width":"45%"},attrs:{"required":"","label":"报修来源","labelCol":{span: 9},"wrapperCol":{span: 15}}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  (_vm.title + "order_source[" + item + "]"),
                  {
                    initialValue: _vm.arr[item] ? _vm.arr[item].order_source : undefined,
                  } ]),expression:"[\n                  `${title}order_source[${item}]`,\n                  {\n                    initialValue: arr[item] ? arr[item].order_source : undefined,\n                  },\n                ]"}],staticStyle:{"width":"226px"},attrs:{"disabled":_vm.showType==='detail',"placeholder":""}},[_c('a-select-option',{attrs:{"value":"自报"}},[_vm._v("自报")]),_c('a-select-option',{attrs:{"value":"调度中心"}},[_vm._v("调度中心")]),_c('a-select-option',{attrs:{"value":"外部报"}},[_vm._v("外部报")])],1)],1):_vm._e(),(_vm.order_type===1)?_c('a-form-item',{staticStyle:{"width":"45%"},attrs:{"required":"","label":"维修类型","labelCol":{span: 9},"wrapperCol":{span: 15}}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  (_vm.title + "repair_matter_type[" + item + "]"),
                  {
                    initialValue: _vm.arr[item] ? _vm.arr[item].repair_matter_type : undefined,
                  }
                ]),expression:"[\n                  `${title}repair_matter_type[${item}]`,\n                  {\n                    initialValue: arr[item] ? arr[item].repair_matter_type : undefined,\n                  }\n                ]"}],staticStyle:{"width":"226px"},attrs:{"disabled":_vm.showType==='detail',"placeholder":""}},[_c('a-select-option',{attrs:{"value":"公区维修"}},[_vm._v("公区维修")]),_c('a-select-option',{attrs:{"value":"专属维修"}},[_vm._v("专属维修")])],1)],1):_vm._e(),(_vm.order_type===1)?_c('a-form-item',{staticStyle:{"width":"45%"},attrs:{"required":"","label":"报修人姓名","labelCol":{span: 9},"wrapperCol":{span: 15}}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  (_vm.title + "contacts[" + item + "]"),
                  {
                    initialValue: _vm.arr[item] ? _vm.arr[item].contacts : undefined,
                  }
                ]),expression:"[\n                  `${title}contacts[${item}]`,\n                  {\n                    initialValue: arr[item] ? arr[item].contacts : undefined,\n                  }\n                ]"}],staticStyle:{"width":"226px"},attrs:{"disabled":_vm.showType==='detail',"placeholder":""}})],1):_vm._e(),(_vm.order_type===1)?_c('a-form-item',{staticStyle:{"width":"45%"},attrs:{"required":"","label":"报修人电话","labelCol":{span: 9},"wrapperCol":{span: 15}}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  (_vm.title + "contact_information[" + item + "]"),
                  {
                    initialValue: _vm.arr[item] ? _vm.arr[item].contact_information : undefined,
                  }
                ]),expression:"[\n                  `${title}contact_information[${item}]`,\n                  {\n                    initialValue: arr[item] ? arr[item].contact_information : undefined,\n                  }\n                ]"}],staticStyle:{"width":"226px"},attrs:{"disabled":_vm.showType==='detail',"placeholder":""}})],1):_vm._e(),(_vm.order_type===1)?_c('a-form-item',{staticStyle:{"width":"45%"},attrs:{"required":"","label":"设备名称","labelCol":{span: 9},"wrapperCol":{span: 15}}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  (_vm.title + "device_name[" + item + "]"),
                  {
                    initialValue: _vm.arr[item] ? _vm.arr[item].device_name : undefined,
                  }
                ]),expression:"[\n                  `${title}device_name[${item}]`,\n                  {\n                    initialValue: arr[item] ? arr[item].device_name : undefined,\n                  }\n                ]"}],staticStyle:{"width":"226px"},attrs:{"disabled":_vm.showType==='detail',"placeholder":""}})],1):_vm._e(),(_vm.order_type===1)?_c('a-form-item',{staticStyle:{"width":"45%"},attrs:{"label":"设备编号","labelCol":{span: 9},"wrapperCol":{span: 15}}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  (_vm.title + "device_num[" + item + "]"),
                  {
                    initialValue: _vm.arr[item] ? _vm.arr[item].device_num : undefined,
                  }
                ]),expression:"[\n                  `${title}device_num[${item}]`,\n                  {\n                    initialValue: arr[item] ? arr[item].device_num : undefined,\n                  }\n                ]"}],staticStyle:{"width":"226px"},attrs:{"disabled":_vm.showType==='detail',"placeholder":""}})],1):_vm._e(),(_vm.order_type===1)?_c('a-form-item',{staticStyle:{"width":"45%"},attrs:{"required":"","label":"设备位置","labelCol":{span: 9},"wrapperCol":{span: 15}}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  (_vm.title + "device_address[" + item + "]"),
                  {
                    initialValue: _vm.arr[item] ? _vm.arr[item].device_address : undefined,
                  }
                ]),expression:"[\n                  `${title}device_address[${item}]`,\n                  {\n                    initialValue: arr[item] ? arr[item].device_address : undefined,\n                  }\n                ]"}],staticStyle:{"width":"226px"},attrs:{"disabled":_vm.showType==='detail',"placeholder":""}})],1):_vm._e(),(_vm.order_type===1)?_c('a-form-item',{staticStyle:{"width":"45%"},attrs:{"required":"","label":"报修内容","labelCol":{span: 9},"wrapperCol":{span: 15}}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  (_vm.title + "content[" + item + "]"),
                  {
                    initialValue: _vm.arr[item] ? _vm.arr[item].content : undefined,
                  }
                ]),expression:"[\n                  `${title}content[${item}]`,\n                  {\n                    initialValue: arr[item] ? arr[item].content : undefined,\n                  }\n                ]"}],staticStyle:{"width":"226px"},attrs:{"disabled":_vm.showType==='detail',"placeholder":""}})],1):_vm._e(),_c('a-form-item',{staticStyle:{"width":"45%"},attrs:{"label":"工单接收人","labelCol":{span: 9},"wrapperCol":{span: 15}}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  (_vm.title + "process_person[" + item + "]"),
                  {
                    initialValue: _vm.arr[item] ? _vm.arr[item].process_person : undefined,
                  }
                ]),expression:"[\n                  `${title}process_person[${item}]`,\n                  {\n                    initialValue: arr[item] ? arr[item].process_person : undefined,\n                  }\n                ]"}],staticStyle:{"width":"142px"},attrs:{"disabled":_vm.showType==='detail',"placeholder":""}}),_c('a-button',{staticStyle:{"margin-left":"10px"},attrs:{"type":"primary"},on:{"click":function($event){return _vm.openSelectUserModal(_vm.arr[item],item)}}},[_vm._v("请选择")])],1),_c('a-form-item',[(_vm.showType!=='detail'&&_vm.keysList.length > 1)?_c('a-button',{staticClass:"deleteRowBtn",attrs:{"type":"danger","ghost":"","icon":"delete"},on:{"click":function () { return _vm.removeRow(item); }}},[_vm._v(" 删除 ")]):_vm._e()],1),_c('a-divider')],1)}),_c('a-row',[_c('a-col',[_c('a-form-item',{staticStyle:{"margin-left":"10%"}},[(_vm.showType!=='detail')?_c('a-button',{staticClass:"addRowBtn",staticStyle:{"width":"300px"},attrs:{"type":"dashed","icon":"plus"},on:{"click":_vm.addRow}},[_vm._v("新增")]):_vm._e()],1)],1)],1)],2),_c('select-user',{key:"selectmanager",attrs:{"visible":_vm.selectManagerVisible,"select-type":"radio","params":_vm.selectUserParams},on:{"update:visible":function($event){_vm.selectManagerVisible=$event},"get-selected-rows":_vm.getManagerUser}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }