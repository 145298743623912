<template>
  <div>
    <a-modal v-model="modalVisible" :title="modalTitle" :width="900" :centered="true" :body-style="modalBodyStyle">
      <template slot="footer">
        <a-button @click="modalVisible=false">{{'关闭'}}</a-button>
        <a-button type="primary" @click="confirm()">提交</a-button>
      </template>
      <div v-for="(item,index) in arr" :key="index">
        <a-form layout="inline">
        <a-form-item required label="工单类型" style="width: 45%;" :labelCol="{span: 9}" :wrapperCol="{span: 15}">
          <a-select
              style="width: 226px;"
              :disabled="showType==='detail'"
              placeholder=""
              v-model="item.order_type"
              @change="orderTypeChange(index,item)"
          >
            <a-select-option :value="0">报事</a-select-option>
            <a-select-option :value="1">报修</a-select-option>
            <a-select-option :value="2">第三方工单</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item v-if="item.order_type===0" required label="报事来源" style="width: 45%;" :labelCol="{span: 9}" :wrapperCol="{span: 15}">
          <a-select
              style="width: 226px;"
              :disabled="showType==='detail'"
              placeholder=""
              v-model="item.order_source"
          >
            <a-select-option value="自报">自报</a-select-option>
            <a-select-option value="调度中心">调度中心</a-select-option>
            <a-select-option value="外部报">外部报</a-select-option>
          </a-select>
        </a-form-item>
          <a-form-item v-if="item.order_type===2" required label="事件类型" style="width: 45%;" :labelCol="{span: 9}" :wrapperCol="{span: 15}">
            <a-select
                style="width: 226px;"
                :disabled="showType==='detail'"
                placeholder=""
                v-model="item.third_type"
            >
              <a-select-option value="报事">报事</a-select-option>
              <a-select-option value="报修">报修</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item v-if="item.order_type===2" required label="事件来源" style="width: 45%;" :labelCol="{span: 9}" :wrapperCol="{span: 15}">
            <a-select
                style="width: 226px;"
                :disabled="showType==='detail'"
                placeholder=""
                v-model="item.order_source"
            >
              <a-select-option value="自报">自报</a-select-option>
              <a-select-option value="调度中心">调度中心</a-select-option>
              <a-select-option value="外部报">外部报</a-select-option>
            </a-select>
          </a-form-item>
        <a-form-item v-if="item.order_type===0" required label="报事人姓名" style="width: 45%;" :labelCol="{span: 9}" :wrapperCol="{span: 15}">
          <a-input
              style="width: 226px;"
              :disabled="showType==='detail'"
              placeholder=""
              v-model="item.contacts"
          >
          </a-input>
        </a-form-item>
        <a-form-item v-if="item.order_type===0" required label="报事人电话" style="width: 45%;" :labelCol="{span: 9}" :wrapperCol="{span: 15}">
          <a-input
              style="width: 226px;"
              :disabled="showType==='detail'"
              placeholder=""
              v-model="item.contact_information"
          >
          </a-input>
        </a-form-item>
          <a-form-item v-if="item.order_type===2" required label="联系人姓名" style="width: 45%;" :labelCol="{span: 9}" :wrapperCol="{span: 15}">
            <a-input
                style="width: 226px;"
                :disabled="showType==='detail'"
                placeholder=""
                v-model="item.contacts"
            >
            </a-input>
          </a-form-item>
          <a-form-item v-if="item.order_type===2" required label="联系人电话" style="width: 45%;" :labelCol="{span: 9}" :wrapperCol="{span: 15}">
            <a-input
                style="width: 226px;"
                :disabled="showType==='detail'"
                placeholder=""
                v-model="item.contact_information"
            >
            </a-input>
          </a-form-item>
        <a-form-item v-if="item.order_type===0" required label="报事类型" style="width: 45%;" :labelCol="{span: 9}" :wrapperCol="{span: 15}">
            <a-select
                style="width: 226px;"
                :disabled="showType==='detail'"
                placeholder=""
                v-model="item.repair_matter_type"
            >
              <a-select-option value="建议">建议</a-select-option>
              <a-select-option value="问询">问询</a-select-option>
              <a-select-option value="质疑">质疑</a-select-option>
              <a-select-option value="求助">求助</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item v-if="item.order_type===2" required label="报事报修类型" style="width: 45%;" :labelCol="{span: 9}" :wrapperCol="{span: 15}">
            <a-select
                style="width: 226px;"
                :disabled="showType==='detail'"
                placeholder=""
                v-model="item.repair_matter_type"
            >
              <a-select-option value="建议">建议</a-select-option>
              <a-select-option value="问询">问询</a-select-option>
              <a-select-option value="质疑">质疑</a-select-option>
              <a-select-option value="求助">求助</a-select-option>
              <a-select-option value="公区维修">公区维修</a-select-option>
              <a-select-option value="专属维修">专属维修</a-select-option>
            </a-select>
          </a-form-item>
        <a-form-item v-if="item.order_type===0||item.order_type===2" required label="重大影响" style="width: 45%;" :labelCol="{span: 9}" :wrapperCol="{span: 15}">
          <a-select
              style="width: 226px;"
              :disabled="showType==='detail'"
              v-model="item.biginfluence"
          >
            <a-select-option v-for="(item, index) in bigInfluences" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item v-if="item.order_type===0||item.order_type===2" required label="报事内容" style="width: 45%;" :labelCol="{span: 9}" :wrapperCol="{span: 15}">
          <textarea
              style="width: 226px;"
              :disabled="showType==='detail'"
              placeholder=""
              v-model="item.content"
          >
          </textarea>
          </a-form-item>
        <a-form-item v-if="item.order_type===1" required label="报修类别" style="width: 45%;" :labelCol="{span: 9}" :wrapperCol="{span: 15}">
          <a-select
              style="width: 226px;"
              :disabled="showType==='detail'"
              placeholder=""
              v-model="item.is_plan_repair"
          >
            <a-select-option value="0">普通维修</a-select-option>
            <a-select-option value="1">计划性维修</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item v-if="item.order_type===1" required label="报修来源" style="width: 45%;" :labelCol="{span: 9}" :wrapperCol="{span: 15}">
          <a-select
              style="width: 226px;"
              :disabled="showType==='detail'"
              placeholder=""
              v-model="item.order_source"
          >
            <a-select-option value="自报">自报</a-select-option>
            <a-select-option value="调度中心">调度中心</a-select-option>
            <a-select-option value="外部报">外部报</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item v-if="item.order_type===1" required label="报修人姓名" style="width: 45%;" :labelCol="{span: 9}" :wrapperCol="{span: 15}">
          <a-input
              style="width: 226px;"
              :disabled="showType==='detail'"
              placeholder=""
              v-model="item.contacts"
          >
          </a-input>
        </a-form-item>
        <a-form-item v-if="item.order_type===1" required label="报修人电话" style="width: 45%;" :labelCol="{span: 9}" :wrapperCol="{span: 15}">
          <a-input
              style="width: 226px;"
              :disabled="showType==='detail'"
              placeholder=""
              v-model="item.contact_information"
          >
          </a-input>
        </a-form-item>
        <a-form-item v-if="item.order_type===1" required label="维修类型" style="width: 45%" :labelCol="{span: 9}" :wrapperCol="{span: 15}">
          <a-select style="width: 226px;" :disabled="showType=='detail'" v-model="item.repair_matter_type">
            <a-select-option value="公区维修">公区维修</a-select-option>
            <a-select-option value="专属维修">专属维修</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item v-if="item.order_type===1&&item.repair_matter_type==='专属维修'&&item.is_plan_repair!=='1'" required label="专属维修条目" style="width: 45%" :labelCol="{span: 9}" :wrapperCol="{span: 15}">
          <a-select style="width: 226px;" v-model="item.entry_id" show-search :filter-option="filterOption">
            <a-select-option v-for="(item, index) in exclusiveRepair" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
          </a-select>
        </a-form-item>
        <h5 v-if="item.order_type===1&&item.repair_matter_type==='专属维修'&&item.is_plan_repair!=='1'" style="color: red;width: 226px;margin-left: 145px">说明：若无对应专属维修条目，则表示物业服务合同不提供该服务，请联系管家咨询如何处理，谢谢！</h5>
        <a-form-item v-if="item.order_type===1&&item.repair_matter_type==='专属维修'&&item.is_plan_repair!=='1'" required label="维修服务时间" style="width: 45%" :labelCol="{span: 9}" :wrapperCol="{span: 15}">
          <a-input :disabled="true" style="width: 160px;" v-model="item.service_time"></a-input><a-button type="primary" @click="makeAppointment">预约</a-button>
        </a-form-item>
        <a-form-item v-if="item.order_type===1" required label="重大影响" style="width: 45%;" :labelCol="{span: 9}" :wrapperCol="{span: 15}">
            <a-select
                style="width: 226px;"
                :disabled="showType==='detail'"
                v-model="item.biginfluence"
            >
              <a-select-option v-for="(item, index) in bigInfluences" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
            </a-select>
          </a-form-item>
        <a-form-item v-if="item.order_type===1" required label="设备名称" style="width: 45%;" :labelCol="{span: 9}" :wrapperCol="{span: 15}">
          <a-input
              style="width: 226px;"
              :disabled="showType==='detail'"
              placeholder=""
              v-model="item.device_name"
          >
          </a-input>
        </a-form-item>
        <a-form-item v-if="item.order_type===1" label="设备编号" style="width: 45%;" :labelCol="{span: 9}" :wrapperCol="{span: 15}">
          <a-input
              style="width: 226px;"
              :disabled="showType==='detail'"
              placeholder=""
              v-model="item.device_num"
          >
          </a-input>
        </a-form-item>
        <a-form-item v-if="item.order_type===1" required label="设备位置" style="width: 45%;" :labelCol="{span: 9}" :wrapperCol="{span: 15}">
          <a-input
              style="width: 226px;"
              :disabled="showType==='detail'"
              placeholder=""
              v-model="item.device_address"
          >
          </a-input>
        </a-form-item>
        <a-form-item v-if="item.order_type===1" required label="报修内容" style="width: 45%;" :labelCol="{span: 9}" :wrapperCol="{span: 15}">
          <textarea
              style="width: 226px;"
              :disabled="showType==='detail'"
              placeholder=""
              v-model="item.content"
          >
          </textarea>
          </a-form-item>
          <a-form-item v-if="item.order_type===1&&item.is_plan_repair==='1'" required label="计划开始时间" style="width: 45%;" :labelCol="{span: 9}" :wrapperCol="{span: 15}">
            <a-date-picker
                style="width: 226px;"
                :disabled="showType==='detail'"
                show-time
                placeholder=""
                v-model="item.need_start_time"
            >
            </a-date-picker >
          </a-form-item>
          <a-form-item v-if="item.order_type===1&&item.is_plan_repair==='1'" required label="计划完成时间" style="width: 45%;" :labelCol="{span: 9}" :wrapperCol="{span: 15}">
            <a-date-picker
                style="width: 226px;"
                :disabled="showType==='detail'"
                show-time
                placeholder=""
                v-model="item.need_finish_time"
            >
            </a-date-picker >
          </a-form-item>

        <a-form-item v-if="item.order_type===0||item.order_type===1" required label="工单接收人" style="width: 45%;" :labelCol="{span: 9}" :wrapperCol="{span: 15}">
          <a-input
              style="width: 142px;"
              :disabled="true"
              placeholder=""
              v-model="item.process_person_name"
          >
          </a-input>
          <a-button type="primary" @click="openSelectUserModal(index,item)" style="margin-left: 10px;">请选择</a-button>
        </a-form-item>
        <a-form-item>
          <a-button
              type="danger"
              ghost
              class="deleteRowBtn"
              v-if="showType!=='detail'&&arr.length > 0"
              @click="() => removeRow(index,item)"
              icon="delete"
          >
            删除
          </a-button>
        </a-form-item>
        <a-divider></a-divider>
        </a-form>
      </div>
      <a-row>
        <a-col>
          <a-form-item style="margin-left: 10%">
            <a-button v-if="showType!=='detail'" type="dashed" class="addRowBtn" @click="addRow" icon="plus" style="width: 300px">新增</a-button>
          </a-form-item>
        </a-col>
      </a-row>
    </a-modal>
    <select-user key="selectmanager" :visible.sync="selectManagerVisible" @get-selected-rows="getManagerUser" select-type="radio" :params="selectUserParams"></select-user>
    <appointment :visible.sync="isAppoint" :detailData="appointDetail" @get-appointment="getAppointment"></appointment>
  </div>
</template>
<script>
import moment from 'moment'
import { mapState } from 'vuex'
import areaselect from '@/mixins/areaselect'
import deptselect from '@/mixins/deptselect'
import {getMonitorPointNameListByCondition, getPointParamsList} from "A/monitoring";
import {getCache} from "U/index";
import {
  addRepairsOrder, getExclusiveRepair,
  getRepairsOrderListByCondition,
  modifyRepairsOrder, splitRepairsOrder
} from "A/repairsreport";
import DynamicForm from "V/matterAndRepairs/myTodo/dynamicform";
import SelectUser from "V/matterAndRepairs/myTodo/SelectUser";
import appointment from "V/matterAndRepairs/repair/appointment.vue";
const PARTONE = 'partOne'
export default {
  mixins: [areaselect, deptselect],
  components:{
    appointment,
    DynamicForm,
    SelectUser,
  },
  props: {
    visible: {
      default: false
    },
    showType: {
      default: 'split'
    },
    detailData: {
      default: null
    }
  },
  data() {
    return {
      moment,
      modalBodyStyle: {
        height: '600px',
        overflowY: 'auto',
      },
      modalVisible: false,
      formData: {
        monitorpointnum: '',
        order_num: '',
        order_type: '',
        process_node: '',
        process_person: '',
        process_person_name: '',
        address: '',
        need_start_time: null,
        need_finish_time: null,
        content: '',
        contacts: '',
        contact_information: '',
        order_decs: '',
        fault: '',
        order_status: '',
        create_account: '',
        order_source: '',
        third_type: '',
        repair_matter_type: '',
        is_plan_repair: '',
        entry_id: '',
        service_time:'',
        biginfluence: '',
        customer_evaluation: '',
        whether_evaluate: '否',
        whether_check: '否',
      },
      params:[{
        order_type:"",
        order_source: "",
        third_type: "",
        order_level:"",
        monitorpointnum:"",
        process_node:"",
        biginfluence:"",
        repair_matter_type: "",
        entry_id: "",
        service_time:'',
        content: "",
        contacts: "",
        contact_information: "",
        customer_evaluation: "",
        need_start_time: null,
        need_finish_time: null,
        is_plan_repair:"",
        process_person:"",
        process_person_name:"",
        process_dispatcher:"",
        parent_order_id:"",
        handle_status:"",
        device_num:"",
        device_name:"",
        device_address:"",
        whether_evaluate:"否",
        whether_check: '否',
      }],
      //这里面的数据属于必填项
      formRules: {
        monitorpointnum: [{required: true, message: '请选择项目'}],
        order_type: [{required: true, message: '请选择工单类型'}],
        need_start_time: [{required: true, message: '请选择计划开始的时间'}],
        need_finish_time: [{required: true, message: '请选择计划完成的时间'}],
        content: [{required: true, message: '请填写工单具体内容'}],
        contacts: [{required: true, message: '请填写报事联系人'}],
        contact_information: [{required: true, message: '请填写报事联系方式'}],
        order_source: [{required: true, message: '请选择报事来源'}],
        third_type: [{required: true, message: '请选择事件类型'}],
        repair_matter_type: [{required: true, message: '请选择报事报修类型'}],
        entry_id: [{required: true, message: '请选择专属维修类型'}],
        service_time: [{required: true, message: '请选择维修服务时间'}],
        biginfluence: [{required: true, message: '请选择重大影响'}],
        customer_evaluation: [{required: true, message: '请选择是否需要客户评价'}],
      },
      monitorpointList:[],
      bigInfluences:[],
      exclusiveRepair:[],
      form: this.$form.createForm(this),
      PARTONE,
      arr: [ // 模拟从接口获取到的数据（如编辑场景）
        {
          order_type:"",
          order_source: "",
          third_type: "",
          repair_matter_type: "",
          entry_id: "",
          service_time:'',
          biginfluence: "",
          contacts: "",
          contact_information: "",
          customer_evaluation: "否",
          content: "",
          need_start_time: null,
          need_finish_time: null,
          receive_time: null,
          order_level:1,
          monitorpointnum:"",
          process_node:10,
          process_person:"",
          process_person_name:"",
          process_dispatcher:"",
          parent_order_id:"",
          handle_status:"",
          device_num:"",
          device_name:"",
          device_address:"",
          is_plan_repair:"",
          whether_evaluate:"否",
          whether_check: '否',
        }],
      index:0,
      spiltModalVisible:false,
      spiltModalDetailData:null,
      selectManagerVisible:false,
      selectUserParams:{},
      managerUser:null,
      dataValid:true,

      isAppoint:false,
      appointDetail:{monitorpointnum:''},
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    modalTitle() {
      if(this.showType == 'split') {
        return '工单拆分'
      }else {
        return '';
      }
    },
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.resetData();
        this.getMonitorPointNameList();
        this.initDetail();
        this.getExclusiveRepairList();
      }
    },
    modalVisible(val) {
      if(!val) {
        this.resetData();
        this.$emit('get-operation-result', 'success', '操作成功');
        this.$emit('update:visible', false);
      }
    },
  },
  created() {
    this.modalVisible = this.visible;
    this.initDeptOptionsAll();
    let dictionary = getCache('dictionary', true);
    if(dictionary) {
      this.bigInfluences = dictionary.bigInfluences;
    }
  },
  methods: {
    getMonitorPointNameList(){
      getMonitorPointNameListByCondition({userdepsid:this.userInfo.userdepid}).then(res=>{
        if(res&&res.returncode=='0'){
          this.monitorpointList=res.item
        }
      })
    },
    getExclusiveRepairList(){
      getExclusiveRepair({monitorpointnum:this.detailData.monitorpointnum}).then(res=>{
        if(res&&res.returncode=='0'){
          this.exclusiveRepair=res.item
        }
      })
      getPointParamsList({monitorpointnum:this.detailData.monitorpointnum}).then(res=>{
        if(res&&res.returncode=='0'&&res.item.length){
          for(let i=0;i<21;i++){
            if(res.item[0][i]!=null){
              this.exclusiveRepairTimes.push({key:i,value:res.item[0][i]})
            }
          }
        }
      })
    },
    resetData() {
      if (this.$refs.modalForm) {
        this.$refs.modalForm.resetFields();
      }
      this.arr= [ // 模拟从接口获取到的数据（如编辑场景）
        {
          order_type:"",
          order_source: "",
          third_type: "",
          repair_matter_type: "",
          entry_id: "",
          service_time: "",
          biginfluence: "",
          contacts: "",
          contact_information: "",
          customer_evaluation: "否",
          content: "",
          need_start_time: null,
          need_finish_time: null,
          receive_time: null,
          order_level:1,
          monitorpointnum:"",
          process_node:10,
          process_person:"",
          process_person_name:"",
          process_dispatcher:"",
          parent_order_id:"",
          handle_status:"",
          device_num:"",
          device_name:"",
          device_address:"",
          whether_evaluate:"否",
          whether_check: '否',
        }]
    },
    initDetail() {
      if (this.detailData && this.detailData.order_id) {
        if(this.showType == 'edit' || this.showType == 'split') {
          this.$nextTick(() => {
            let params = {
              order_id: this.detailData.order_id
            }
            getRepairsOrderListByCondition(params).then(res => {
              if (res && res.returncode == '0') {
                this.formData = res.item[0];
                this.formData.need_start_time=moment(res.item[0].need_start_time,"YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss")
                this.formData.need_finish_time=moment(res.item[0].need_finish_time,"YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss")
                this.formData.receive_time=moment(res.item[0].receive_time,"YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss")
                this.arr[0].order_type=this.formData.order_type
                this.arr[0].order_source=this.formData.order_source
                this.arr[0].biginfluence=this.formData.biginfluence
                this.arr[0].contacts=this.formData.contacts
                this.arr[0].contact_information=this.formData.contact_information
                this.arr[0].content=this.formData.content
                if(this.formData.order_type===1){
                  this.arr[0].repair_matter_type=this.formData.repair_matter_type
                  this.arr[0].device_num=this.formData.device_num
                  this.arr[0].device_name=this.formData.device_name
                  this.arr[0].device_address=this.formData.device_address
                  if(this.formData.repair_matter_type==="专属维修"){
                    this.arr[0].entry_id=this.formData.entry_id
                    this.arr[0].service_time=this.formData.service_time
                  }
                  if(this.formData.is_plan_repair==="1"){
                    this.arr[0].need_start_time=this.formData.need_start_time
                    this.arr[0].need_finish_time=this.formData.need_finish_time
                  }
                }
              }
            })
          })
        }
      }
    },
    confirm() {
      this.dataValid=true
      this.params=this.arr
      if (this.showType == 'add' || this.showType == 'edit' || this.showType == 'split') {
        for (let i = 0; i < this.params.length; i++) {
          this.params[i].monitorpointnum=this.detailData.monitorpointnum
          this.params[i].parent_order_id=this.detailData.order_id
          this.params[i].need_start_time=moment(this.params[i].need_start_time).format("yyyyMMDDHHmmss")
          this.params[i].need_finish_time=moment(this.params[i].need_finish_time).format("yyyyMMDDHHmmss")
          this.params[i].receive_time=this.detailData.receive_time
          if(this.params[i].order_type===0){
            this.params[i].is_plan_repair="0"
          }else if(this.params[i].order_type===2){
            this.params[i].process_node=91
          }
          if(this.params[i].order_type===""){
            this.$message.warning('请填写工单类型！')
            this.dataValid=false
            break
          }else if(this.params[i].order_type===0){
            for (let key in this.params[i]) {
              if (!this.params[i].order_source) {
                this.$message.warning('请填写报事来源！')
                this.dataValid=false
                break
              }else if(!this.params[i].repair_matter_type){
                this.$message.warning('请选择报事类型！')
                this.dataValid=false
                break
              }else if(!this.params[i].biginfluence){
                this.$message.warning('请选择重大影响')
                this.dataValid=false
                break
              }else if(!this.params[i].content){
                this.$message.warning('请填写报事内容')
                this.dataValid=false
                break
              }else if(!this.params[i].contacts){
                this.$message.warning('请填写报事人姓名')
                this.dataValid=false
                break
              }else if(!this.params[i].contact_information){
                this.$message.warning('请填写报事人电话')
                this.dataValid=false
                break
              }else if(!this.params[i].process_person){
                this.$message.warning('请选择工单接收人')
                this.dataValid=false
                break
              }
            }
          }else if(this.params[i].order_type===1){
            for (let key in this.params[i]) {
              if(!this.params[i].is_plan_repair){
                this.$message.warning('请选择报修类别！')
                this.dataValid=false
                break
              }else if (!this.params[i].order_source) {
                this.$message.warning('请填写报修来源！')
                this.dataValid=false
                break
              }else if(!this.params[i].contacts){
                this.$message.warning('请填写报修人姓名')
                this.dataValid=false
                break
              }else if(!this.params[i].contact_information){
                this.$message.warning('请填写报修人电话')
                this.dataValid=false
                break
              }else if(!this.params[i].repair_matter_type){
                this.$message.warning('请选择维修类型！')
                this.dataValid=false
                break
              }else if(this.params[i].repair_matter_type==="专属维修"&&this.params[i].is_plan_repair!=='1'&&!this.params[i].entry_id){
                this.$message.warning('请选择专属维修条目！')
                this.dataValid=false
                break
              }else if(this.params[i].repair_matter_type==="专属维修"&&this.params[i].is_plan_repair!=='1'&&!this.params[i].service_time){
                this.$message.warning('请选择维修服务时间！')
                this.dataValid=false
                break
              }else if(!this.params[i].biginfluence){
                this.$message.warning('请选择重大影响')
                this.dataValid=false
                break
              }else if(!this.params[i].content){
                this.$message.warning('请填写报修内容')
                this.dataValid=false
                break
              }else if(!this.params[i].need_start_time){
                this.$message.warning('请选择计划开始时间')
                this.dataValid=false
                break
              }else if(!this.params[i].need_finish_time){
                this.$message.warning('请选择计划结束时间')
                this.dataValid=false
                break
              }else if(!this.params[i].process_person){
                this.$message.warning('请选择工单接收人')
                this.dataValid=false
                break
              }
            }
          }else if(this.params[i].order_type===2){
            for (let key in this.params[i]) {
              if (!this.params[i].order_source) {
                this.$message.warning('请填写事件来源！')
                this.dataValid=false
                break
              }else if(!this.params[i].third_type){
                this.$message.warning('请选择事件类型！')
                this.dataValid=false
                break
              }else if(!this.params[i].repair_matter_type){
                this.$message.warning('请选择报事报修类型！')
                this.dataValid=false
                break
              }else if(!this.params[i].biginfluence){
                this.$message.warning('请选择重大影响')
                this.dataValid=false
                break
              }else if(!this.params[i].content){
                this.$message.warning('请填写接报内容')
                this.dataValid=false
                break
              }else if(!this.params[i].contacts){
                this.$message.warning('请填写联系人姓名')
                this.dataValid=false
                break
              }else if(!this.params[i].contact_information){
                this.$message.warning('请填写联系人电话')
                this.dataValid=false
                break
              }
            }
          }
        }
        let params = {
          data: this.params,
        };
        if (this.showType === 'split'&&this.dataValid) {
          this.showLoading();
          splitRepairsOrder(params).then(res => {
            this.hideLoading();
            if (res && res.returncode == '0') {
              modifyRepairsOrder({
                order_id: this.detailData.order_id,
                order_type: this.detailData.order_type,
                order_status: 3,
                process_node: 91,
                finish_time: moment(new Date()).format("YYYYMMDDHHmmss"),
                whether_evaluate:"是",
                whether_check:'是',
              }).then(res => {
                if (res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                }
              })
            } else {
              this.$message.error(res.errormsg || '操作失败');
            }
          })
        }
      } else {
        this.$message.warning('请确认信息填写完整')
        return false;
      }
    },
    dynamicFormChange(val){
      this.index=val.index
      if(val.type==='新增一行'){
        this.arr.push({
          order_type: this.formData.order_type,
          order_source: this.formData.order_source,
          repair_matter_type: "",
          entry_id: "",
          service_time: "",
          biginfluence: this.formData.biginfluence,
          is_plan_repair: "",
          contacts: this.formData.contacts,
          contact_information: this.formData.contact_information,
          customer_evaluation: "否",
          content: this.formData.content,
          need_start_time: null,
          need_finish_time: null,
          order_level:1,
          monitorpointnum:this.detailData.monitorpointnum,
          process_node:10,
          process_person:"",
          process_person_name:"",
          process_dispatcher:"",
          parent_order_id:"",
          handle_status:"",
          device_num:"",
          device_name:"",
          device_address:"",
          whether_evaluate:"否",
          whether_check: '否',
        })
      }else if(val.type==='移除一行'){
        this.arr.splice(val.index, 1)
        for(let i=0;i<this.arr.length;i++){
          this.params.push({
            order_source:this.arr[i].order_source,
            repair_matter_type:this.arr[i].repair_matter_type,
            entry_id:this.arr[i].entry_id,
            service_time:this.arr[i].service_time,
            biginfluence:this.arr[i].biginfluence,
            contacts:this.arr[i].contacts,
            contact_information:this.arr[i].contact_information,
            customer_evaluation:"否",
            content:this.arr[i].content,
            need_start_time:moment(this.arr[i].need_start_time).format("YYYYMMDDHHmmss"),
            need_finish_time:moment(this.arr[i].need_finish_time).format("YYYYMMDDHHmmss"),
            process_node:10,
            monitorpointnum:this.detailData.monitorpointnum,
            order_level:1,
            is_plan_repair: this.arr[i].is_plan_repair,
            process_person:this.arr[i].process_person,
            process_person_name:this.arr[i].process_person_name,
            process_dispatcher:this.arr[i].process_dispatcher,
            parent_order_id:this.arr[i].parent_order_id,
            handle_status:this.arr[i].handle_status,
            device_num:this.arr[i].device_num,
            device_name:this.arr[i].device_name,
            device_address:this.arr[i].device_address,
            whether_evaluate:"否",
            whether_check: '否',
          })
        }
      }
    },
    getSplitModalList(){
      const {form: {validateFields } } = this
      validateFields((errors, values) => {
        if (!errors) {
          const partOneArr = []
          values[`${PARTONE}order_type`].forEach((item, index) => {
            const obj = {
              order_type: values[`${PARTONE}order_type`][index],
              order_source: values[`${PARTONE}order_source`][index],
              repair_matter_type: values[`${PARTONE}repair_matter_type`][index],
              entry_id: values[`${PARTONE}entry_id`][index],
              service_time: values[`${PARTONE}service_time`][index],
              biginfluence: values[`${PARTONE}biginfluence`][index],
              is_plan_repair: values[`${PARTONE}is_plan_repair`][index],
              contacts: values[`${PARTONE}contacts`][index],
              contact_information: values[`${PARTONE}contact_information`][index],
              customer_evaluation: values[`${PARTONE}customer_evaluation`][index],
              content: values[`${PARTONE}content`][index],
              need_start_time: values[`${PARTONE}need_start_time`][index],
              need_finish_time: values[`${PARTONE}need_finish_time`][index],
              order_level:1,
              monitorpointnum:this.detailData.monitorpointnum,
              process_node:10,
              process_person:values[`${PARTONE}process_person`][index],
              process_person_name:values[`${PARTONE}process_person_name`][index],
              process_dispatcher:values[`${PARTONE}process_dispatcher`][index],
              parent_order_id:values[`${PARTONE}parent_order_id`][index],
              handle_status:values[`${PARTONE}handle_status`][index],
              device_num:values[`${PARTONE}device_num`][index],
              device_name:values[`${PARTONE}device_name`][index],
              device_address:values[`${PARTONE}device_address`][index],
              whether_evaluate:"否",
              whether_check: '否',
            }
            partOneArr.push(obj)
          })
          this.params=[]
          for(let i=0;i<partOneArr.length;i++){
            this.params.push({
              order_type:partOneArr[i].order_type,
              order_source:partOneArr[i].order_source,
              repair_matter_type:partOneArr[i].repair_matter_type,
              entry_id:partOneArr[i].entry_id,
              service_time:partOneArr[i].service_time,
              biginfluence:partOneArr[i].biginfluence,
              is_plan_repair:partOneArr[i].is_plan_repair,
              contacts:partOneArr[i].contacts,
              contact_information:partOneArr[i].contact_information,
              customer_evaluation:partOneArr[i].customer_evaluation,
              content:partOneArr[i].content,
              need_start_time:moment(partOneArr[i].need_start_time).format("YYYYMMDDHHmmss"),
              need_finish_time:moment(partOneArr[i].need_finish_time).format("YYYYMMDDHHmmss"),
              process_node:10,
              monitorpointnum:this.detailData.monitorpointnum,
              order_level:1,
              process_person:partOneArr[i].process_person,
              process_person_name:partOneArr[i].process_person_name,
              process_dispatcher:partOneArr[i].process_dispatcher,
              parent_order_id:partOneArr[i].parent_order_id,
              handle_status:partOneArr[i].handle_status,
              device_num:partOneArr[i].device_num,
              device_name:partOneArr[i].device_name,
              device_address:partOneArr[i].device_address,
              whether_evaluate:"否",
              whether_check: '否',
            })
          }
        }
      })
    },
    // 新增一行
    addRow() {
      // this.id = this.id + 1
      // this.arr = this.arr.concat(this.arr.length)
      // console.log("新增一行:",this.keysList.length)
      let params = {
        type:"新增一行",
        index:this.arr.length
      }
      this.dynamicFormChange(params)
    },
    // 移除某行
    removeRow(k) {
      if (this.arr.length === 1) {
        // 如果存在可以移除所有行的情况，把条件改为this.arr.length === 0即可
        return
      }
      this.arr = this.arr.filter(item => item !== k)
      let params = {
        type:"移除一行",
        index:k
      }
      this.dynamicFormChange(params)
      //console.log("移除第",k,"行")
    },
    orderTypeChange(e,item){
      this.order_type=item
      this.arr[e].order_type=item.order_type
    },
    openSelectUserModal(index,item) {
      this.index=index
      this.selectUserParams.monitorpointnum=this.detailData.monitorpointnum
      this.selectManagerVisible = true
    },
    getManagerUser(selectedRows) {
      this.arr[this.index].process_person = selectedRows[0].useraccount;
      this.arr[this.index].process_person_name = selectedRows[0].username;
      this.arr[this.index].process_dispatcher = selectedRows[0].useraccount;
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },

    makeAppointment(){
      this.isAppoint=true
      this.appointDetail.monitorpointnum=this.formData.monitorpointnum
    },
    getAppointment(val){
      this.arr[this.index].service_time=val
    }
  }
}
</script>
<style lang="scss" scoped>
.select-drop-down-platform {
  width: 240px;
}
.dynamic-wrap {
  padding-top: 10px;
}
.minusRowBtn {
  color: #f5222d;
  background: #fff1f0;
  border-color: #ffa39e;
  padding-right: 7px;
  padding-left: 7px;
  height: 29px;
  margin-left: 10px;
}
.deleteRowBtn {
  width: 100%;
  margin-left: 142px;
}
.addRowBtn {
  width: 80%;
  color: #1890ff;
  border-color: #91d5ff;
  margin-left: -3px;
}
</style>