<template>
  <div>
    <div class="dynamic-wrap">
      <div v-for="item in keysList" :key="item">
        <a-form-item required label="工单类型" style="width: 45%;" :labelCol="{span: 9}" :wrapperCol="{span: 15}">
          <a-select
              style="width: 226px;"
              :disabled="showType==='detail'"
              placeholder=""
              v-decorator="[
                  `${title}order_type[${item}]`,
                  {
                    initialValue: arr[item] ? arr[item].order_type : undefined,
                  },
                ]"
              @change="orderTypeChange(item,arr[item])"
          >
            <a-select-option :value="0">报事</a-select-option>
            <a-select-option :value="1">报修</a-select-option>
            <a-select-option :value="2">第三方工单</a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item v-if="arr[item].order_type===0" required label="报事来源" style="width: 45%;" :labelCol="{span: 9}" :wrapperCol="{span: 15}">
            <a-select
                style="width: 226px;"
                :disabled="showType==='detail'"
                placeholder=""
                v-decorator="[
                  `${title}order_source[${item}]`,
                  {
                    initialValue: arr[item] ? arr[item].order_source : undefined,
                  },
                ]"
            >
              <a-select-option value="自报">自报</a-select-option>
              <a-select-option value="调度中心">调度中心</a-select-option>
              <a-select-option value="外部报">外部报</a-select-option>
            </a-select>
          </a-form-item>
        <a-form-item v-if="order_type===0" required label="报事类型" style="width: 45%;" :labelCol="{span: 9}" :wrapperCol="{span: 15}">
          <a-select
              style="width: 226px;"
              :disabled="showType==='detail'"
              placeholder=""
              v-decorator="[
                  `${title}repair_matter_type[${item}]`,
                  {
                    initialValue: arr[item] ? arr[item].repair_matter_type : undefined,
                  }
                ]"
          >
            <a-select-option value="建议">建议</a-select-option>
            <a-select-option value="问询">问询</a-select-option>
            <a-select-option value="质疑">质疑</a-select-option>
            <a-select-option value="求助">求助</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item v-if="order_type===0" required label="报事人姓名" style="width: 45%;" :labelCol="{span: 9}" :wrapperCol="{span: 15}">
          <a-input
              style="width: 226px;"
              :disabled="showType==='detail'"
              placeholder=""
              v-decorator="[
                  `${title}contacts[${item}]`,
                  {
                    initialValue: arr[item] ? arr[item].contacts : undefined,
                  }
                ]"
          >
          </a-input>
        </a-form-item>
        <a-form-item v-if="order_type===0" required label="报事人电话" style="width: 45%;" :labelCol="{span: 9}" :wrapperCol="{span: 15}">
          <a-input
              style="width: 226px;"
              :disabled="showType==='detail'"
              placeholder=""
              v-decorator="[
                  `${title}contact_information[${item}]`,
                  {
                    initialValue: arr[item] ? arr[item].contact_information : undefined,
                  }
                ]"
          >
          </a-input>
        </a-form-item>
<!--        <a-form-item v-if="order_type===0" required label="是否需要客户评价" style="width: 45%;" :labelCol="{span: 9}" :wrapperCol="{span: 15}">-->
<!--          <a-select-->
<!--              style="width: 226px;"-->
<!--              :disabled="showType==='detail'"-->
<!--              placeholder=""-->
<!--              v-decorator="[-->
<!--                  `${title}customer_evaluation[${item}]`,-->
<!--                  {-->
<!--                    initialValue: arr[item] ? arr[item].customer_evaluation : undefined,-->
<!--                  }-->
<!--                ]"-->
<!--          >-->
<!--            <a-select-option value="是">是</a-select-option>-->
<!--            <a-select-option value="否">否</a-select-option>-->
<!--          </a-select>-->
<!--        </a-form-item>-->
<!--        <a-form-item v-if="order_type===0" required label="计划完成时间" style="width: 45%;" :labelCol="{span: 9}" :wrapperCol="{span: 15}">-->
<!--          <a-date-picker-->
<!--              style="width: 226px;"-->
<!--              :disabled="showType==='detail'"-->
<!--              show-time-->
<!--              placeholder=""-->
<!--              v-decorator="[-->
<!--                  `${title}need_finish_time[${item}]`,-->
<!--                  {-->
<!--                    initialValue: arr[item] ? arr[item].need_finish_time : undefined,-->
<!--                  }-->
<!--                ]"-->
<!--          >-->
<!--          </a-date-picker >-->
<!--        </a-form-item>-->
        <a-form-item v-if="order_type===0" required label="报事内容" style="width: 45%;" :labelCol="{span: 9}" :wrapperCol="{span: 15}">
          <a-input
              style="width: 226px;"
              :disabled="showType==='detail'"
              placeholder=""
              v-decorator="[
                  `${title}content[${item}]`,
                  {
                    initialValue: arr[item] ? arr[item].content : undefined,
                  }
                ]"
          >
          </a-input>
        </a-form-item>

<!--        <a-form-item v-if="order_type===1" required label="报修类别" style="width: 45%;" :labelCol="{span: 9}" :wrapperCol="{span: 15}">-->
<!--          <a-select-->
<!--              style="width: 226px;"-->
<!--              :disabled="showType==='detail'"-->
<!--              placeholder=""-->
<!--              v-decorator="[-->
<!--                  `${title}is_plan_repair[${item}]`,-->
<!--                  {-->
<!--                    initialValue: arr[item] ? arr[item].is_plan_repair : undefined,-->
<!--                  }-->
<!--                ]"-->
<!--          >-->
<!--            <a-select-option value="0">普通维修</a-select-option>-->
<!--            <a-select-option value="1">计划性维修</a-select-option>-->
<!--          </a-select>-->
<!--        </a-form-item>-->
        <a-form-item v-if="order_type===1" required label="报修来源" style="width: 45%;" :labelCol="{span: 9}" :wrapperCol="{span: 15}">
          <a-select
              style="width: 226px;"
              :disabled="showType==='detail'"
              placeholder=""
              v-decorator="[
                  `${title}order_source[${item}]`,
                  {
                    initialValue: arr[item] ? arr[item].order_source : undefined,
                  },
                ]"
          >
            <a-select-option value="自报">自报</a-select-option>
            <a-select-option value="调度中心">调度中心</a-select-option>
            <a-select-option value="外部报">外部报</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item v-if="order_type===1" required label="维修类型" style="width: 45%;" :labelCol="{span: 9}" :wrapperCol="{span: 15}">
          <a-select
              style="width: 226px;"
              :disabled="showType==='detail'"
              placeholder=""
              v-decorator="[
                  `${title}repair_matter_type[${item}]`,
                  {
                    initialValue: arr[item] ? arr[item].repair_matter_type : undefined,
                  }
                ]"
          >
            <a-select-option value="公区维修">公区维修</a-select-option>
            <a-select-option value="专属维修">专属维修</a-select-option>
          </a-select>
        </a-form-item>
<!--        <a-form-item v-if="order_type===1" required label="有无受困" style="width: 45%;" :labelCol="{span: 9}" :wrapperCol="{span: 15}">-->
<!--          <a-select-->
<!--              style="width: 226px;"-->
<!--              :disabled="showType==='detail'"-->
<!--              placeholder=""-->
<!--              v-decorator="[-->
<!--                  `${title}is_trapped[${item}]`,-->
<!--                  {-->
<!--                    initialValue: arr[item] ? arr[item].is_trapped : undefined,-->
<!--                  }-->
<!--                ]"-->
<!--          >-->
<!--            <a-select-option value="有">有</a-select-option>-->
<!--            <a-select-option value="无">无</a-select-option>-->
<!--          </a-select>-->
<!--        </a-form-item>-->
        <a-form-item v-if="order_type===1" required label="报修人姓名" style="width: 45%;" :labelCol="{span: 9}" :wrapperCol="{span: 15}">
          <a-input
              style="width: 226px;"
              :disabled="showType==='detail'"
              placeholder=""
              v-decorator="[
                  `${title}contacts[${item}]`,
                  {
                    initialValue: arr[item] ? arr[item].contacts : undefined,
                  }
                ]"
          >
          </a-input>
        </a-form-item>
        <a-form-item v-if="order_type===1" required label="报修人电话" style="width: 45%;" :labelCol="{span: 9}" :wrapperCol="{span: 15}">
          <a-input
              style="width: 226px;"
              :disabled="showType==='detail'"
              placeholder=""
              v-decorator="[
                  `${title}contact_information[${item}]`,
                  {
                    initialValue: arr[item] ? arr[item].contact_information : undefined,
                  }
                ]"
          >
          </a-input>
        </a-form-item>
        <a-form-item v-if="order_type===1" required label="设备名称" style="width: 45%;" :labelCol="{span: 9}" :wrapperCol="{span: 15}">
          <a-input
              style="width: 226px;"
              :disabled="showType==='detail'"
              placeholder=""
              v-decorator="[
                  `${title}device_name[${item}]`,
                  {
                    initialValue: arr[item] ? arr[item].device_name : undefined,
                  }
                ]"
          >
          </a-input>
        </a-form-item>
        <a-form-item v-if="order_type===1" label="设备编号" style="width: 45%;" :labelCol="{span: 9}" :wrapperCol="{span: 15}">
          <a-input
              style="width: 226px;"
              :disabled="showType==='detail'"
              placeholder=""
              v-decorator="[
                  `${title}device_num[${item}]`,
                  {
                    initialValue: arr[item] ? arr[item].device_num : undefined,
                  }
                ]"
          >
          </a-input>
        </a-form-item>
        <a-form-item v-if="order_type===1" required label="设备位置" style="width: 45%;" :labelCol="{span: 9}" :wrapperCol="{span: 15}">
          <a-input
              style="width: 226px;"
              :disabled="showType==='detail'"
              placeholder=""
              v-decorator="[
                  `${title}device_address[${item}]`,
                  {
                    initialValue: arr[item] ? arr[item].device_address : undefined,
                  }
                ]"
          >
          </a-input>
        </a-form-item>
<!--        <a-form-item v-if="order_type===1" required label="是否需要客户评价" style="width: 45%;" :labelCol="{span: 9}" :wrapperCol="{span: 15}">-->
<!--          <a-select-->
<!--              style="width: 226px;"-->
<!--              :disabled="showType==='detail'"-->
<!--              placeholder=""-->
<!--              v-decorator="[-->
<!--                  `${title}customer_evaluation[${item}]`,-->
<!--                  {-->
<!--                    initialValue: arr[item] ? arr[item].customer_evaluation : undefined,-->
<!--                  }-->
<!--                ]"-->
<!--          >-->
<!--            <a-select-option value="是">是</a-select-option>-->
<!--            <a-select-option value="否">否</a-select-option>-->
<!--          </a-select>-->
<!--        </a-form-item>-->
<!--        <a-form-item v-if="order_type===1" required label="计划完成时间" style="width: 45%;" :labelCol="{span: 9}" :wrapperCol="{span: 15}">-->
<!--          <a-date-picker-->
<!--              style="width: 226px;"-->
<!--              :disabled="showType==='detail'"-->
<!--              show-time-->
<!--              placeholder=""-->
<!--              v-decorator="[-->
<!--                  `${title}need_finish_time[${item}]`,-->
<!--                  {-->
<!--                    initialValue: arr[item] ? arr[item].need_finish_time : undefined,-->
<!--                  }-->
<!--                ]"-->
<!--          >-->
<!--          </a-date-picker >-->
<!--        </a-form-item>-->
        <a-form-item v-if="order_type===1" required label="报修内容" style="width: 45%;" :labelCol="{span: 9}" :wrapperCol="{span: 15}">
          <a-input
              style="width: 226px;"
              :disabled="showType==='detail'"
              placeholder=""
              v-decorator="[
                  `${title}content[${item}]`,
                  {
                    initialValue: arr[item] ? arr[item].content : undefined,
                  }
                ]"
          >
          </a-input>
        </a-form-item>

        <a-form-item label="工单接收人" style="width: 45%;" :labelCol="{span: 9}" :wrapperCol="{span: 15}">
          <a-input
              style="width: 142px;"
              :disabled="showType==='detail'"
              placeholder=""
              v-decorator="[
                  `${title}process_person[${item}]`,
                  {
                    initialValue: arr[item] ? arr[item].process_person : undefined,
                  }
                ]"
          >
          </a-input>
          <a-button type="primary" @click="openSelectUserModal(arr[item],item)" style="margin-left: 10px;">请选择</a-button>
        </a-form-item>
        <a-form-item>
            <a-button
                type="danger"
                ghost
                class="deleteRowBtn"
                v-if="showType!=='detail'&&keysList.length > 1"
                @click="() => removeRow(item)"
                icon="delete"
            >
              删除
            </a-button>
          </a-form-item>
        <a-divider></a-divider>
      </div>
      <a-row>
        <a-col>
          <a-form-item style="margin-left: 10%">
            <a-button v-if="showType!=='detail'" type="dashed" class="addRowBtn" @click="addRow" icon="plus" style="width: 300px">新增</a-button>
          </a-form-item>
        </a-col>
      </a-row>
    </div>
    <select-user key="selectmanager" :visible.sync="selectManagerVisible" @get-selected-rows="getManagerUser" select-type="radio" :params="selectUserParams"></select-user>
  </div>
</template>
<script>
import moment from 'moment'
import SelectUser from "V/matterAndRepairs/myTodo/SelectUser";
import {commitWorkflow} from "A/repairsreport";
export default {
  name: 'DynamicForm',
  props: {
    title: {
      type: String,
      default: ''
    },
    showType: {
      default: 'add'
    },
    arr: {
      type: Array,
      default: function() {
        return []
      }
    }
  },
  components:{
    SelectUser,
  },
  data() {
    return {
      labelCol: {
        span: 10
      },
      wrapperCol: {
        span: 14
      },
      id: 0,
      keysList: [],
      moment,
      order_type:null,
      spiltModalVisible:false,
      spiltModalDetailData:null,
      selectManagerVisible:false,
      selectUserParams:{},
      managerUser:null,
      index:0,
    }
  },
  watch:{
    arr:function (){
      const arr = [0]
      if (this.arr.length !== 0) {
        for (let i = 1; i < this.arr.length; i++) {
          arr.push(i)
          // this.id = this.id + 1
        }
      }
      this.keysList = arr
    },
  },
  created() {
    this.form = this.$form.createForm(this)
    this.init()
  },
  methods: {
    orderTypeChange(e,item){
      this.order_type=item
      this.arr[e].order_type=item.order_type
    },
    // 初始化
    init() {
      if(this.arr.length===1&&this.arr[0].order_source===''){
        const arr = [0]
        if (this.arr.length !== 0) {
          for (let i = 1; i < this.arr.length; i++) {
            arr.push(i)
            this.id = this.id + 1
          }
        }
        this.keysList = arr
      }
    },
    // 移除某行
    removeRow(k) {
      if (this.keysList.length === 1) {
        // 如果存在可以移除所有行的情况，把条件改为this.keysList.length === 0即可
        return
      }
      this.keysList = this.keysList.filter(item => item !== k)
      let params = {
        type:"移除一行",
        index:k
      }
      this.$emit('update-result', params);
      //console.log("移除第",k,"行")
    },
    // 新增一行
    addRow() {
      this.id = this.id + 1
      this.keysList = this.keysList.concat(this.keysList.length)
      // console.log("新增一行:",this.keysList.length)
      let params = {
        type:"新增一行",
        index:this.keysList.length
      }
      this.$emit('update-result', params);
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    openSelectUserModal(record,item) {
      this.index=item
      this.selectUserParams.monitorpointnum=record.monitorpointnum
      this.selectManagerVisible = true
    },
    getManagerUser(selectedRows) {
      this.arr[this.index].process_person = selectedRows[0].useraccount;
      this.arr[this.index].process_person_name = selectedRows[0].username;
      this.arr[this.index].process_dispatcher = selectedRows[0].useraccount;
    },
  }
}
</script>
<style lang="scss" scoped>
.dynamic-wrap {
  padding-top: 10px;
}
.minusRowBtn {
  color: #f5222d;
  background: #fff1f0;
  border-color: #ffa39e;
  padding-right: 7px;
  padding-left: 7px;
  height: 29px;
  margin-left: 10px;
}
.deleteRowBtn {
  width: 100%;
  margin-left: 50px;
}
.addRowBtn {
  width: 80%;
  color: #1890ff;
  border-color: #91d5ff;
  margin-left: -3px;
}
</style>
