<template>
    <a-layout v-if="isMobile===1" id="components-layout-top" class="layout">
      <div v-if="readCommand" style="width: 100%;height: 100%">
        <a-layout-header class="readCommandheader" @contextmenu.prevent="handleClick">
          <div style="height: 100%;width: 15%;display: flex;align-items: center" @click="clickBack">
            <a-icon type="left" :style="{ fontSize: '23px',marginLeft:'5px'}" />返回
          </div>
        </a-layout-header>
        <a-layout-content class="readCommand">
          <gsddy v-if="gsddyEnable"></gsddy>
          <phoneapp v-if="phoneappEnable"></phoneapp>
          <xmddy v-if="xmddyEnable"></xmddy>
          <xmgly v-if="xmglyEnable"></xmgly>
          <xtwhgly v-if="xtwhglyEnable"></xtwhgly>
        </a-layout-content>
      </div>
      <div v-else style="width: 100%;height: 100%">
        <a-layout-header class="top_header" @contextmenu.prevent="handleClick">
          <div style="height: 23px;width: 100%;display: flex;align-items: center;justify-content: right;padding-right: 15px;margin-top: 5px;">
            <div  @click="clickLogout" style="height: 100%;width: 15%;display: flex;align-items: center;">
              <a-icon type="logout" :style="{ fontSize: '23px',marginLeft:'5px'}" />退出
            </div>
          </div>
          <div class="top_video">
            <video v-if="videoUrl"
                   :src="videoUrl"
                   controls
                   controlsList="nodownload"
                   disablePictureInPicture
                   ref="videoPlayer"
                   autoplay
                   style="width: 100%;height:100%;"></video>
          </div>
        </a-layout-header>
        <a-layout-content >
          <a-tabs >
            <template v-for="(item,index) in asideMenuTree">
              <a-tab-pane :key="index" :tab="item.menuname" v-if="item.menuname!=='系统配置'">
                <a-list item-layout="horizontal" v-if="item.children && item.children.length"  :data-source="item.children">
                  <a-list-item slot="renderItem" slot-scope="subitem, subindex" style="padding: 10px 15px;" @click="clickWeChatMenuItem(subitem.menuname)">
                    <span>{{ subitem.menuname }}</span>
                  </a-list-item>
                </a-list>
                <a-list item-layout="horizontal" v-else-if="item.menuname==='平台宣讲视频'">
                  <a-list-item style="padding: 10px 15px;" @click="clickWeChatMenuItem('宣讲视频')">
                    <span>{{item.menuname}}</span>
                  </a-list-item>
                </a-list>
              </a-tab-pane>
            </template>
          </a-tabs>
        </a-layout-content>
      </div>
    </a-layout>
    <a-layout v-else style="flex: 1 0 auto;">
      <div v-if="!fullScreen">
        <a-layout-sider v-model="collapsed" :trigger="null" collapsible width="208" class="main-aside"
                        style="flex: 1 0 auto;">
          <div class="logo">
            <img v-if="!collapsed" src="~@/assets/image/logo.png" style="margin-left: -33px">
            <img v-else src="~@/assets/image/logo.png">
            <span v-show="!collapsed">"卫全物管"全域监管平台</span>
          </div>
          <a-menu
              mode="inline"
              theme="dark"
              class="aside-menu"
              :inline-collapsed="collapsed"
              :selected-keys="selectedKeys"
              :style="{ borderRight: 0, height: 'calc(100vh - 91px)', overflow: 'auto' }">
            <template v-for="item in asideMenuTree">
              <sub-menu v-if="item.children && item.children.length" :key="item.menuid" :menu-info="item"
                        @click-menu-item="clickMenuItem"></sub-menu>
              <a-menu-item v-else :key="item.menuid" @click="clickMenuItem(item)">
                <a-icon v-if="item.menupic" :type="item.menupic"/>
                <span>{{ item.menuname }}</span>
              </a-menu-item>
            </template>
          </a-menu>
          <div style="height: 40px;"></div>
          <div class="trigger-bottom" :class="collapsed?'trigger-collapsed':''">
            <a-icon
                class="trigger"
                :type="collapsed ? 'menu-unfold' : 'menu-fold'"
                @click="() => ($store.commit('main/setCollapsed', !collapsed))"
            />
          </div>
        </a-layout-sider>
      </div>
      <a-layout style="overflow: hidden;position: relative;">
        <a-layout-header class="header" v-if="!fullScreen">
          <div class="tab-group">
            <page-tabs size="large" :active-key="selectedTab" @change="tabChange" @edit="tabEdit">
              <a-tab-pane v-for="(item, index) in tabs" :key="item.id" :tab="item.name" :closable="index>0"></a-tab-pane>
            </page-tabs>
          </div>
          <div
              v-if="this.userInfo.rolename==='项目调度员'||this.userInfo.rolename==='项目工程值班员'||this.userInfo.rolename==='项目管理员'||this.userInfo.rolename==='项目工程管理员'||this.userInfo.rolename==='项目其他管理员'||this.userInfo.rolename==='管家'">
            <a-icon v-if="this.userInfo.rolename==='项目调度员'&&!repairsFlag&&repairsNum>0" type="bell"
                    style="font-size: 28px;" @click="showRepairs()"/>
            <a-icon v-if="this.userInfo.rolename==='项目调度员'&&repairsFlag&&repairsNum>0" type="bell"
                    style="font-size: 28px;color: red" @click="showRepairs()"/>
            <a-badge v-if="this.userInfo.rolename==='项目调度员'" :count="repairsNum" :overflow-count="99"
                     style="margin-bottom: 25px;margin-right: 20px"><a href="#" class="head-example"/></a-badge>

            <a-icon v-if="!flag&&alarmNum>0" type="alert" style="font-size: 28px;" @click="showAlarm()"/>
            <a-icon v-if="flag&&alarmNum>0" type="alert" style="font-size: 28px;color: red" @click="showAlarm()"/>
            <a-badge :count="alarmNum" :overflow-count="99" style="margin-bottom: 25px;"><a href="#"
                                                                                            class="head-example"/>
            </a-badge>
          </div>
          <div class="header-right">
            <a-dropdown>
              <div class="user-avatar">
                <a-avatar icon="user"/>
                <span style="display: inline-block; margin-left: 10px;">{{ $store.state.userInfo.username }}</span>
              </div>
              <a-menu slot="overlay" @click="avatarClick">
                <a-menu-item key="user">个人中心</a-menu-item>
                <!--                <a-menu-item key="download" @click="download('vlc-3.0.16-win32.exe','vlc-3.0.16-win32.exe')">插件下载</a-menu-item>-->
                <a-menu-item key="logout">退出登录</a-menu-item>
              </a-menu>
            </a-dropdown>
          </div>
        </a-layout-header>
        <a-layout-content
            :style="{ position: 'relative', padding: fullScreen?'0':'50px 0 0 0', minHeight: '280px', height: '100vh', flexShrink: '0', flexGrow: '0', overflow: 'auto'}"
            @scroll="
        pageScroll"
        >
          <transition name="page-move">
            <keep-alive :include="$store.state.keepAliveComponents">
              <router-view/>
            </keep-alive>
          </transition>
        </a-layout-content>
        <div v-if="selectedKeys[0]=='home'" class="big-bg"></div>
      </a-layout>
    </a-layout>


</template>
<script>
import moment from "moment";
import {mapState, mapGetters} from 'vuex'
import {logout} from 'A/login'
import PageTabs from 'C/PageTabs'
import {Menu} from 'ant-design-vue';
import {filterOperationMenu, getCache, getItemFromArrayByKey, removeCache, setCache} from 'U'
import {editUser} from "A/xtpz";
import {getUnhandleIotEventrecord} from "A/monitoring";
import pagination from "@/mixins/pagination";
import addOrEditModal from "V/patrols/patrolsRecord/alarmRecord/addOrEditModal";
import addOrEditSplitModal from "V/matterAndRepairs/myTodo/spiltModal";
import {commitWorkflow, getRepairsOrderListByCondition} from "A/repairsreport";
import {repairOrderStepColor, repairOrderSteps} from "@/json/repairsOrderSteps";
import SelectUser from "V/matterAndRepairs/myTodo/SelectUser";
import BaseVideo from "V/xtpz/videoTutorial/baseVideo.vue";
import gsddy from "V/xtpz/usersmanual/gsddy.vue";
import phoneapp from "V/xtpz/usersmanual/phoneapp.vue";
import xmddy from "V/xtpz/usersmanual/xmddy.vue";
import xmgly from "V/xtpz/usersmanual/xmgly.vue";
import xtwhgly from "V/xtpz/usersmanual/xtwhgly.vue";

const SubMenu = {
  template: `
    <a-sub-menu :key="menuInfo.menuid" v-bind="$props" v-on="$listeners">
        <span slot="title">
          <a-icon v-if="menuInfo.menupic" :type="menuInfo.menupic"/>
          <span>{{ menuInfo.menuname }}</span>
        </span>
      <template v-for="item in menuInfo.children">
        <sub-menu v-if="item.children && item.children.length" :key="item.menuid" :menu-info="item"
                  @click-menu-item="clickMenuItem"/>
        <a-menu-item v-else :key="item.menuid" @click="clickMenuItem(item)">
          <a-icon v-if="item.menupic" :type="item.menupic"/>
          <span>{{ item.menuname }}</span>
        </a-menu-item>
      </template>
    </a-sub-menu>
  `,
  name: 'SubMenu',
  // must add isSubMenu: true
  isSubMenu: true,
  props: {
    ...Menu.SubMenu.props,
    // Cannot overlap with properties within Menu.SubMenu.props
    menuInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    clickMenuItem(item) {
      this.$emit('click-menu-item', item);
    },
  }
};
export default {
  mixins: [pagination],
  components: {
    SubMenu,
    PageTabs,
    addOrEditModal,
    SelectUser,
    addOrEditSplitModal,
    BaseVideo,
    gsddy,
    phoneapp,
    xmddy,
    xmgly,
    xtwhgly
  },
  data() {
    return {
      play_show: false,
      playtype: 0,
      userid: '',
      moment,
      picUrl: require('../../assets/image/shouye.png'),
      alarmNum: 0,
      flag: false,
      alarmVisible: false,
      timer: null,
      alarmTypes: [],
      alarmChildTypes: [],
      systemNamesMap: {},
      tableLoading: false,
      tableData: [],
      tableColumns: [
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
        },
        {
          title: '系统名称',
          dataIndex: 'systemnameid',
          key: 'systemnameid',
          ellipsis: true,
          scopedSlots: {customRender: 'systemnameid'},
        },
        // {
        //   title: '报警类型',
        //   dataIndex: 'eventname',
        //   key: 'eventname',
        //   ellipsis: true,
        // },
        // {
        //   title: '报警子类型',
        //   dataIndex: 'eventchildname',
        //   key: 'eventchildname',
        //   ellipsis: true,
        // },
        {
          title: '报警时间',
          dataIndex: 'eventstarttime',
          key: 'eventstarttime',
          ellipsis: true,
          scopedSlots: {customRender: 'eventstarttime'},
        },
        {
          title: '编号/位置',
          dataIndex: 'location',
          key: 'location',
          ellipsis: true,
        },
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'operation',
          ellipsis: true,
          align: 'center',
          scopedSlots: {customRender: 'operation'},
          width: 70
        },
      ],
      modalVisible: false,
      modalShowType: 'record',
      modalDetailData: null,
      repairsNum: 0,
      repairsFlag: false,
      repairsVisible: false,
      repairOrderStepColor,
      repairOrderSteps,
      tableRepairsData: [],
      tableRepairsColumns: [
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
        },
        {
          title: '报事/报修单号',
          dataIndex: 'order_num',
          key: 'order_num',
          ellipsis: true,
        },
        {
          title: '报事/报修来源',
          dataIndex: 'order_source',
          key: 'order_source',
          ellipsis: true,
        },
        {
          title: '报事/报修类型',
          dataIndex: 'repair_matter_type',
          key: 'repair_matter_type',
          ellipsis: true,
        },
        {
          title: '联系人',
          dataIndex: 'contacts',
          key: 'contacts',
          ellipsis: true,
        },
        {
          title: '联系方式',
          dataIndex: 'contact_information',
          key: 'contact_information',
          ellipsis: true,
        },
        {
          title: '接报内容',
          dataIndex: 'content',
          key: 'content',
          ellipsis: true,
        },
        // {
        //   title: '有无受困',
        //   dataIndex: 'is_trapped',
        //   key: 'is_trapped',
        //   ellipsis: true,
        // },
        // {
        //   title: '计划完成日期',
        //   dataIndex: 'need_finish_time',
        //   key: 'need_finish_time',
        //   ellipsis: true,
        //   scopedSlots: { customRender: 'need_finish_time' },
        // },
        {
          title: '工单状态',
          dataIndex: 'order_status',
          key: 'order_status',
          ellipsis: true,
          scopedSlots: {customRender: 'order_status'},
        },
        {
          title: '节点状态',
          dataIndex: 'process_node',
          key: 'process_node',
          ellipsis: true,
          align: "center",
          width: 100,
          scopedSlots: {customRender: 'process_node'},
        },
        {
          title: '详情',
          dataIndex: 'detail',
          key: 'detail',
          ellipsis: true,
          align: 'center',
          scopedSlots: {customRender: 'detail'},
          width: 70
        },
        {
          title: '派单',
          dataIndex: 'dispatcher',
          key: 'dispatcher',
          ellipsis: true,
          align: 'center',
          scopedSlots: {customRender: 'dispatcher'},
          width: 70
        },
        {
          title: '拆单',
          dataIndex: 'split',
          key: 'split',
          ellipsis: true,
          align: 'center',
          scopedSlots: {customRender: 'split'},
          width: 70
        },
        // {
        //   title: '操作',
        //   dataIndex: 'operation',
        //   key: 'operation',
        //   ellipsis: true,
        //   align:'center',
        //   scopedSlots: { customRender: 'operation' },
        //   width: 70
        // },
      ],
      formData: {
        monitorpointnum: '',
        monitorpointname: '',
        order_num: '',
        order_type: '',
        process_node: '',
        process_person: '',
        process_person_name: '',
        address: '',
        need_finish_time: null,
        receive_time: null,
        content: '',
        contacts: '',
        contact_information: '',
        order_decs: '',
        fault: '',
        order_status: '',
        create_account: '',
        order_source: '',
        repair_matter_type: '',
        device_num: '',
        device_name: '',
        device_address: '',
        is_trapped: '',
        is_plan_repair: '0',
        customer_evaluation: '否',
      },
      orderModalVisible: false,
      selectManagerVisible: false,
      managerUser: null,
      selectUserParams: {},
      spiltModalVisible: false,
      spiltModalDetailData: null,
      isMobile: "0",
      videoUrl:'',
      defaultActiveKey:'0',
      readCommand:false,
      gsddyEnable:false,
      phoneappEnable:false,
      xmddyEnable:false,
      xmglyEnable:false,
      xtwhglyEnable:false,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo,
      collapsed: state => state.main.collapsed,
      // menuList: state => state.menuList,
      selectedKeys: state => state.main.selectedKeys,
      tabs: state => state.main.tabs,
      selectedTab: state => state.main.selectedTab,
      fullScreen: state => state.main.fullScreen,
    }),
    ...mapGetters(['asideMenuTree'])
  },
  mounted() {
    let that = this;
    if (this.userInfo.rolename === '项目调度员' || this.userInfo.rolename === '项目工程值班员' || this.userInfo.rolename === '项目管理员' || this.userInfo.rolename === '项目工程管理员' || this.userInfo.rolename === '项目其他管理员') {
      this.timers = setInterval(() => {
        that.getTableData()
        if (that.userInfo.rolename === '项目调度员') {
          that.getTableRepairsData()
        }
      }, 30 * 1000)
    }
  },
  created() {
    this.init()
    let dictionary = getCache('dictionary', true);
    if (dictionary) {
      this.alarmTypes = dictionary.alarmTypes;
      this.alarmTypesMap = dictionary.alarmTypesMap;
      this.systemNames = dictionary.systemNames;
      this.systemNamesMap = dictionary.systemNamesMap;
    }
    this.timer = setInterval(this.alarmTwinkle, 500)
    this.getTableData()
    this.getTableRepairsData()
  },
  methods: {
    handlePlaytype() {
      this.play_show = false
      this.modifyPlayType(this.playtype, this.usertype, this.userdepid)
      logout().then(res => {
        removeCache('logininfo', true);
        this.$store.commit('main/resetTabs');
        this.$store.commit('setToken');
        this.$router.push('/login')
      }).catch(err => {
        removeCache('logininfo', true);
        this.$store.commit('main/resetTabs');
        this.$store.commit('setToken');
        this.$router.push('/login')
      })
    },
    onChange(e) {
      this.playtype = e.target.value
      this.play_show = true
    },
    download(filename, name) {
      let a = document.createElement('a');
      a.href = './' + filename;
      //路径中'/'为根目录，即index.html所在的目录
      a.download = name;
      a.click();
    },
    modifyPlayType(playtype, usertype, userdepid) {
      let params = {
        userid: this.userid,
        status: 1,
        usertype: usertype,
        playtype: playtype,
        userdepid: userdepid,
        remotenable: this.remotenable ? 1 : 0,
      }
      editUser(params).then(res => {
      })
    },
    init() {
      let isMobile = getCache("isMobile", true);
      this.isMobile = isMobile ? isMobile : "0";
      if(this.isMobile===1 && !this.videoUrl){
        //this.videoUrl = 'file/trainingVideo/' + '宣讲视频.mp4'
      }
      let logininfo = getCache('logininfo', true);
      if (logininfo) {
        this.userid = logininfo.usersid
        this.usertype = logininfo.usertype
        this.playtype = logininfo.playtype
        this.userdepid = logininfo.userdepid
        this.remotenable = logininfo.remotenable
        if (logininfo.usertype == 6) {
          this.show = true
        }
      }
    },
    setMenuActived() {
      this.$store.commit('main/setSelectedKeys', [this.$route.name]);
    },
    clickMenuItem(menuItem) {
      if (menuItem.menuurl) {
        this.$router.push({name: menuItem.menuurl, params: {menuid: menuItem.menuid}})
      }
    },
    tabChange(key) {
      this.$router.push({name: key})
    },
    tabEdit(key, action) {
      if (action == 'remove') {
        if (key == 'elevatorArchives' && this.$route.name == 'timeMonitoring') {
          this.$router.push({name: 'home'})
        }
        if ((key == 'enquipmentList' || key == 'equipmentGroup') && this.$route.name == 'paramsConfig') {
          this.$router.push({name: 'home'})
        }
        if (this.selectedTab == key) {
          this.$router.push({name: 'home'})
        }
        this.$store.commit('main/delTab', key)
      }
    },
    avatarClick({key}) {
      if (key == 'logout') {
        logout().then(res => {
          // if(res && res.returncode == '0') {
          removeCache('logininfo', true);
          this.$store.commit('main/resetTabs');
          this.$store.commit('setToken');
          this.$router.push({
            name: 'login',
            params: {
              flag: true
            }
          })
          // }else {
          //   this.$message.error(res.errormsg||'操作失败');
          // }
        }).catch(err => {
          removeCache('logininfo', true);
          this.$store.commit('main/resetTabs');
          this.$store.commit('setToken');
          this.$router.push({
            name: 'login',
            params: {
              flag: true
            }
          })
        })
      } else if (key == 'user') {
        this.$router.push({name: 'userCenter'})
      } else if (key == 'download') {
      }
    },
    pageScroll(event) {
      let ele = event.target || event.srcElement;
      this.$store.commit('setPageScrollInfo', {top: ele.scrollTop, left: ele.scrollLeft});
    },
    //未确认物联网报警
    getTableData(firstPage) {
      if (firstPage) {
        this.currentPage = 1;
      }
      getUnhandleIotEventrecord({
        dispatcher: this.userInfo.rolename === '项目工程值班员' ? 1 : 0,
        rolename: this.userInfo.rolename,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      }).then(res => {
        if (res && res.returncode === "0") {
          this.alarmNum = res.count;
          this.tableData = res.item;
          this.pagination.total = res.count;
          if (this.alarmNum == 0) {
            this.flag = false
          }
        }
      })
    },
    alarmTwinkle() {
      if (this.alarmNum > 0) {
        if (this.flag) {
          this.flag = false
        } else {
          this.flag = true
        }
        if (this.repairsFlag) {
          this.repairsFlag = false
        } else {
          this.repairsFlag = true
        }
      }
    },
    showAlarm() {
      this.alarmVisible = true
    },
    confirmAlarm(record) {
      if (this.userInfo.rolename === '项目调度员' || this.userInfo.rolename === '项目工程值班员') {
        this.modalDetailData = {
          monitorpointnum: '',
          eventnum: '',
          systemnameid: '',
          eventcode: '',
          eventchildcode: '',
          eventstarttime: '',
          writetime: '',
          timerange: '',
          eventrecordid: '',
          biginfluence: 1,
          repairorder: "无",
          plannedwork: 0,
          confirmedevent: false,
        }
        this.modalDetailData.eventcode = record.eventcode
        this.modalDetailData.eventchildcode = record.eventchildcode
        this.modalDetailData.systemnameid = record.systemnameid.toString()
        this.modalDetailData.eventstarttime = moment(record.eventstarttime, 'YYYYMMDDHHmmss').format("YYYY-MM-DD HH:mm:ss")
        this.modalDetailData.writetime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
        this.modalDetailData.eventrecordid = record.eventrecordid
        this.modalDetailData.biginfluence = "1"
        this.modalDetailData.repairorder = "无"
        this.modalDetailData.plannedwork = 0
        this.modalDetailData.eventtype = 901
        this.modalDetailData.recordman = this.userInfo.username
        this.modalDetailData.confirmman = this.userInfo.username
        if (this.modalDetailData.eventcode == "0821" || this.modalDetailData.eventcode == "07") {
          this.modalDetailData.confirmedevent = true
        } else {
          this.modalDetailData.confirmedevent = false
        }
        this.modalVisible = true
        this.modalShowType = 'record'
      } else {
        this.$message.error("此功能仅限项目调度员操作使用")
      }
    },
    showRepairs() {
      this.repairsVisible = true
    },
    //未确认报事报修
    getTableRepairsData(firstPage) {
      if (firstPage) {
        this.currentPage = 1;
      }
      getRepairsOrderListByCondition({
        process_person_name: this.userInfo.username,
        process_dispatcher: this.userInfo.useraccount,
        process_node: this.userInfo.rolename === '项目调度员' ? 20 : 10,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      }).then(res => {
        if (res && res.returncode === "0") {
          this.repairsNum = res.count;
          this.tableRepairsData = res.item;
          this.pagination1.total = res.count;
          if (this.repairsNum == 0) {
            this.repairsFlag = false
          }
        }
      })
    },
    operationClick({key}) {
      let arr = key.split('-');
      let type = arr[0];
      let order_id = arr[1];
      let record = getItemFromArrayByKey(this.tableRepairsData, 'order_id', order_id);
      this.showModal(type, record)
    },
    showModal(type, record) {
      this.modalShowType = type
      if (type === 'split') {
        this.spiltModalDetailData = record
        this.spiltModalVisible = true
      } else if (type === 'dispatcher') {
        if (this.userInfo.rolename === '项目调度员' || this.userInfo.rolename === '项目工程值班员' || this.userInfo.rolename === '管家') {
          this.selectUserParams.monitorpointnum = record.monitorpointnum
          this.selectUserParams.order_id = record.order_id
          this.selectManagerVisible = true
        } else {
          this.$message.error("此功能仅限项目调度员操作使用")
        }
      }
    },
    confirmRepairs(record) {
      if (this.userInfo.rolename === '项目调度员' || this.userInfo.rolename === '项目工程值班员' || this.userInfo.rolename === '管家') {
        this.selectUserParams.monitorpointnum = record.monitorpointnum
        this.selectUserParams.order_id = record.order_id
        this.selectManagerVisible = true
      } else {
        this.$message.error("此功能仅限项目调度员操作使用")
      }
    },
    spiltRepairs(record) {
      this.modalShowType = "split"
      this.spiltModalDetailData = record
      this.spiltModalVisible = true
    },
    getManagerUser(selectedRows) {
      this.managerUser = selectedRows[0];
      let data = {
        nextstep: "10",
        order_id: this.managerUser.order_id,
        nextperson: this.managerUser.useraccount,
        nextpersonname: this.managerUser.username
      };
      commitWorkflow(data).then(res => {
        if (res && res.returncode === '0') {
          this.getTableRepairsData(true)
          this.$message.success("派单成功")
        }
      })
    },
    showOrderDetail(record) {
      this.formData = record
      this.formData.receive_time = moment(record.receive_time, "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss")
      this.formData.need_finish_time = moment(record.need_finish_time, "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss")
      this.orderModalVisible = true
    },
    handleClick(event) {
      event.preventDefault();
    },
    playFlv(msg) {
      if (this.isPlayingUrl !== msg.url) {
        this.$refs.videoPlayer.pause();
        this.isPlayingUrl = msg.url
        this.videoUrl = 'file/trainingVideo/' + msg.url
        this.$refs.videoPlayer.play();
      }
    },
    clickWeChatMenuItem(submenu){
      console.log(submenu)
      //由于历史原因，视频和列表名字不一样，需要判断
      let _name = ''
      if(submenu==="宣讲视频"){
        _name = "宣讲视频"
      }else if(submenu==="项目设置"){
        _name = "项目设置"
      }else if(submenu==="设施设备保养"){
        _name = "设施设备保养子系统"
      }else if(submenu==="环境作业"){
        _name = "清洁、绿化、消杀、白蚁防治环境作业子系统"
      }else if(submenu==="巡查、异常记录与交班"){
        _name = "巡查、交接班子系统"
      }else if(submenu==="报事报修"){
        _name = "报事报修子系统"
      }else if(submenu==="督导和品质报告"){
        _name = "督导管理、品质改进单和一键生成品质报告"
      }else if(submenu==="能耗管理与抄表"){
        _name = "能耗管理与抄表"
      }else if(submenu==="报告报表管理"){
        _name = "报告报表管理"
      }else if(submenu==="台账录入管理"){
        _name = "台账录入管理"
      }else if(submenu==="履约报告及我管我家"){
        _name = "履约报告及我管我家"
      }else if(submenu==="数据分析与报告"){
        _name = "异常历史与分析报表"
      }else if(submenu==="工作查阅与报告"){
        _name = "工作查阅与报告"
      }

      else if(submenu==="调度员（工程值班员）"){
        this.handleReadcommad()
        this.xmddyEnable=true
        return;
      }else if(submenu==="项目管理员"){
        this.handleReadcommad()
        this.xmglyEnable=true
        return;
      }else if(submenu==="公司督导员"){
        this.handleReadcommad()
        this.gsddyEnable=true
        return;
      }else if(submenu==="系统维护管理员"){
        this.handleReadcommad()
        this.xtwhglyEnable=true
        return;
      }else if(submenu==="手机端操作"){
        this.handleReadcommad()
        this.phoneappEnable=true
        return;
      }
      this.videoUrl = _name?'file/trainingVideo/' + _name +".mp4":""
    },
    handleReadcommad(){
      if(this.$refs.videoPlayer){
        this.$refs.videoPlayer.pause();
      }
      this.videoUrl=''
      this.readCommand=true

      this.gsddyEnable=false
      this.phoneappEnable=false
      this.xmddyEnable=false
      this.xmglyEnable=false
      this.xtwhglyEnable=false
    },
    clickBack(){
      //this.$router.push({name: 'home'})
      this.handleReadcommad();
      this.readCommand=false
    },
    clickLogout(){
      removeCache('logininfo', true);
      this.$store.commit('main/resetTabs');
      this.$store.commit('setToken');
      setCache("isMobile",1,true);
      this.$router.push({
        name: 'WeChatLogin',
        params: {
        }
      })
    }
  }
};
</script>
<style lang="scss" scoped>
:global {
  .ant-menu-dark {
    background: black !important;
  }
}

.main-aside {
  position: relative;
  z-index: 2;
  box-shadow: 2px 0px 6px 0px rgba(0, 21, 41, 0.35);
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;

  img {
    height: 30px;
  }

  span {
    display: inline-block;
    margin-left: 8px;
    font-size: 12px;
    color: white;
    white-space: nowrap;
  }
}

.header {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 2;
  display: flex;
  height: 50px;
  background: #fff;
  padding: 0;
  box-shadow: 0px 1px 4px 0px rgba(0, 21, 41, 0.12);
}

.trigger {
  font-size: 18px;
  line-height: 40px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
  flex-shrink: 0;
}

.trigger:hover {
  color: #1890ff;
}

.tab-group {
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
}

.header-right {
  flex-shrink: 0;
  line-height: 50px;
  padding: 0 26px;
}

.trigger-bottom {
  position: fixed;
  z-index: 20;
  bottom: 0;
  left: 0;
  overflow: hidden;
  width: 208px;
  height: 41px;
  color: white;
  border-top: solid 1px #131b24;
  background-color: #001529;
  transition: width ease 0.2s;
}

.trigger-collapsed {
  width: 80px;
}

.page-move-enter-active, .page-move-leave-active {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: transform .3s;
}

.page-move-enter {
  transform: translateX(-100%)
}

.page-move-leave-to {
  transform: translateX(100%)
}

.big-bg {
  position: absolute;
  top: 30px;
  left: 0;
  right: 0;
  bottom: 0;
  background: url('~@/assets/image/shouye.png') no-repeat;
  opacity: 0.9;
  background-size: 100% 100%;
  z-index: 0;
  pointer-events: none;
}

#components-layout-top .readCommandheader{
  width: 100%;
  height: 6%;
  padding: 0;
  background: white;
  display: flex;
  align-items: center;
}

#components-layout-top .readCommand{
  width: 100%;
  height: 94%;
  padding: 0;
}


#components-layout-top .top_header{
  width: 100%;
  height: 33%;
  background: white;
  padding: 0;
}
#components-layout-top .top_video {
  width: 100%;
  height: 95%;
  margin-top: -10px;
}
</style>
